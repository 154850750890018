.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    position: relative;
    z-index: 1001;
  }
  
  .modal-logo {
    width: 200px;
    height: 120px;
}
  
  .modal-message {
    font-size: 1.2rem;
    color: #076D61;
    margin-bottom: 20px;
  }
  
  .modal-close-button {
    background-color: #44ABAD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-close-button:hover {
    background-color: #3A9199;
  }
  