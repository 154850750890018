@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Grifter';
  src: url('../../Assets/grifterbold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.header-darkgreen {
  color: #076D61;
  font-size: 100px; /* Adjust size as needed */
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
}

.header-lightgreen {
  padding-left: 10px;
  color: #44ABAD;
  font-size: 100px; /* Adjust size as needed */
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
}

.about-us-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #DDEBE9; /* Full background color */
  padding: 0px; /* Added padding for the left side */
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  min-height: 100vh;
  min-width: 100vw;
}

.about-us-header {
  margin-bottom: 100px;
  margin-left: 150px;
}

.about-us-header h1 {
  font-size: 3em;
  margin: 0;
}

.about-us-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-left: 150px;
  gap: 20px; /* Added gap for spacing between rows */
}

.left {
  flex: 1;
  min-width: 500px;
  min-height: 400px;
}

.left img {
  width: 100%;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.right {
  flex: 3;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 40px; /* Added gap for vertical spacing between rows */
}

.info-row {
  display: flex;
  gap: 40px; /* Added gap for horizontal spacing between blocks */
  width: 100%;
}

.info-block {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 50px;
  position: relative;
  padding: 0px; /* Added padding inside each block */
  background-color: #DDEBE9; /* Added background color for better visibility */
}

.info-content {
  position: relative;
  z-index: 1;
}

.info-number {
  position: absolute;
  top: -50px; /* Adjust as needed to move the number up */
  left: 0;
  margin-bottom: 50px;
  font-size: 4em;
  font-weight: bold;
  color: rgba(68, 171, 173, 0.2); /* Transparent green */
  z-index: 0;
}

.info-content h2 {
  font-family: 'Figtree', sans-serif;
  font-weight: normal;
  margin-left: 50px;
  margin-bottom: 50px;
  font-size: 1.5em;
  margin: 0 0 30px 0;
  color: #076D61;
}

.info-content p {
  font-family: 'Figtree', sans-serif;
  margin-left: 50px;
  margin-bottom: 50px;
  font-weight: normal;
  margin: 0;
  color: #44ABAD;
}