@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Grifter';
  src: url('../../Assets/grifterbold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Adjusting overall container to handle correct positioning */
.contact-us-container {
  background-color: #DDEBE9;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.contact-us-background {
  position: absolute;
  left: 0;
  top: 0;
  height: 80%; 
  z-index: 0;
  background-size: cover;
}

.content-wrapper {
  flex: 3;
  padding-right : 100px;
  padding-left : 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-header {
  text-align: left;
  /* margin-bottom: 20px; */
  z-index: 2;
}

.contact-us-header h1 {
  color: #076D61;
  font-size: 86px;
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
}

.contact-us-header p {
  color: #076D61;
  font-size: 1.5em;
  margin-top: 10px;
  font-family: 'Grifter', sans-serif;
  font-weight: 300;
}

.contact-form-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 700px;
  margin-top: 0px;
}

.contact-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
}

.contact-form label {
  margin-bottom: 10px;
  color: #076D61;
  font-family: 'Figtree', sans-serif;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 2px solid #076D61;
  border-radius: 4px;
  background: #DDEBE9;
}

.contact-form button {
  background-color: #076D61;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.contact-input-fields {
  color: white;
}

label { color: DDEBE9; font-family: 'Grifter', sans-serif; font-weight: bold;}

.contact-form button:hover {
  background-color: #044a3f;
}

.contact-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.testimonial-section {
  background-color: #44ABAD;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
}

 .footer-content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  z-index: 1;
  font-family: 'Figtree', sans-serif;
} 

.footer-text p,
.footer-text a {
  margin: 0;
  padding: 5px 0;
  padding-right: 20px;
  margin-top: 10px;
  padding-top: 5px;
  z-index: 1;
}

.footer-text a {
  color: #076D61;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-text {
  font-family: 'Figtree', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #076D61;
  text-align: left;
  margin: 0;
}

.footer-logo {
  /* margin-top: 40px; */
}

.footer-line {
  margin-left: 120px;
  width: 85%;
  height: 2px;
  justify-content: center;
  background-color: #076D61;
}

.footer-copyright {
  color: #076D61;
  text-align: center;
}

.footer-text a:hover {
  color: #44ABAD;
}

.footer-logo {
  width: 200px;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .contact-us-header {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .contact-form-wrapper {
    margin: 0 auto;
  }
}
