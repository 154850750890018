body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure full width */
  }
  
  .navbar-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .section {
    width: 100vw; /* Ensure full viewport width */
    box-sizing: border-box; /* Include padding and border in the total width and height */
  }
  
  .home-section, .about-us-section, .services-section, .contact-us-section {
    width: 100%;
  }
  
  * {
    box-sizing: border-box; /* Apply box-sizing to all elements */
  }
  