@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Grifter';
  src: url('../../Assets/grifterbold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@keyframes flyInFade {
0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fly-in {
  display: inline-block;
  animation: flyInFade 1.5s ease-in-out forwards;
  animation-delay: 0.5s; /* Adjust delay as needed */
}

.home-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.homepage-background-svg {
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Send the background behind other content */
}

.overlay-block-left,
.overlay-block-right {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay-block-left {
  z-index: 1;
  left: 0;
  width: 33.33%;
  background-color: rgba(40, 39, 45, 0.9);
}

.overlay-block-left-inner {
  padding-left: 100px; 
}

.overlay-block-right {
  z-index: -1; 
  left: 33.33%;
  width: 66.66%;
  background-color: rgba(40, 39, 45, 0.6);
  padding-left: 60px; 
}

.drive-text {
  color: #DDEBE9;
  font-size: 125px;
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
  z-index: 2; /* Ensure this is above the overlays */
  position: relative;
  white-space: nowrap;
}

.legal-smart-text {
  color: #44ABAD;
  font-size: 125px;
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
  white-space: nowrap;
  z-index: 2; /* Ensure this is above the overlays */
  position: relative;
}

.text-container {
  margin-bottom: 100px;
  margin-left: 50px;
  line-height: 1;
  z-index: 2; /* Ensure this is above the overlays */
  position: relative;
}

.sub-text {
  color: #DDEBE9;
  font-size: 38px;
  margin-top: 10px;
  white-space: nowrap;
  font-weight: lighter;
  font-family: 'Figtree', sans-serif;
  z-index: 2; /* Ensure this is above the overlays */
  display : flex;
}

.buttons {
  margin-left: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  z-index: 2; /* Ensure this is above the overlays */
  position: relative;
}

.button, .services-button {
  margin-top: 50px;;
  padding: 20px 40px; /* Increased padding for larger button size */
  border: 2px solid #DDEBE9;
  border-radius: 5px; /* Reduced border-radius for block-shaped buttons */
  font-size: 20px; /* Increased font size */
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 270px;
  height: 60px; 
}

.button {
  background-color: #DDEBE9;
  color: black;
}

.button:hover {
  background-color: #c9e1dc;
}

.services-button {
  background-color: transparent;
  color: #DDEBE9;
}

.services-button:hover {
  background-color: rgba(221, 235, 233, 0.1);
}