@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%; /* Leave space for scrollbar, 16px is a common scrollbar width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  z-index: -1;
  background-color: rgba(40, 39, 45, 0.9);
  box-sizing: border-box;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(1200px - 25px); /* Adjust max-width if needed */
  justify-content: space-between;
  /* padding: 200 200 0 0; */
  /* padding-top
    padding-right
    padding-bottom
    padding-left */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
}

.nav-links {
  display: flex;
  gap: 40px;
  margin-left: auto;
  font-weight: regular;
  font-family: 'Figtree', sans-serif;
}

.nav-links a {
  color: #DDEBE9;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.nav-links a:hover {
  background-color: #44ABAD;
  border-radius: 5px;
  cursor: pointer;
}
