@font-face {
  font-family: 'Figtree';
  src: url('../../Assets/Figtree-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Grifter';
  src: url('../../Assets/grifterbold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
.our-services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3E3D45; /* Full background color */
  min-height: 100vh;
  min-width: 100vw;
  box-sizing: border-box;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.our-services-header {
  margin-top: 50px;
  text-align: right;
  margin-left: 50px;
  margin-bottom: 50px;
}

.our-services-header h1 {
  font-size: 100px;
  margin: 0;
  font-family: 'Grifter', sans-serif;
  font-weight: bold;
}

.header-white {
  color: #DDEBE9;
  white-space: nowrap;
}

.header-green {
  color: #44ABAD;
  white-space: nowrap;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 150px;
}

.service-block {
  background-color: #2C2B30;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.service-title {
  font-family: 'Grifter', sans-serif;
  font-size: 20px;
  color: #44ABAD;
  margin-bottom: 10px;
}

.service-description {
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  color: #DDEBE9;
}
